import "../styles/Faq.css";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import NavBar from "../components/Navbar";
import Footer from "../components/Footer";
import backgroundImage from "../images/toile_de_jouy_pattern_1.png";
import FaqComponent from "../components/FaqComponent";
import { log, error } from "../utils/log";
import { logPageView } from '../utils/ga';
import { useLocation } from 'react-router-dom';

//chakra import
import {
  ChakraProvider,
  Accordion,
  Flex,
} from "@chakra-ui/react";

function FAQ() {
  //Google analytics
  const location = useLocation();
  useEffect(() => {
    logPageView();
    log("Logging page view...", location.pathname);
  }, [location]);

  const [faqs, setFaqs] = useState([]);

  /**
   * Fetches FAQs data from Firebase through server.js route
   */
  useEffect(() => {
    const fetchFaqs = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/faqs`);
        setFaqs(response.data); //this is where all the faq data are stored
      } catch (error) {
        error("Error fetching faqs:", error);
      }
    };

    fetchFaqs(); 
  }, []);

  return (
    <ChakraProvider>
      <Helmet>
        <title>Frequently Asked Questions</title>
      </Helmet>

      <NavBar />

      <div className="container-fluid background-container">
     
        {/* background image */}
        <div
          className="background-overlay"
          style={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: "cover",
          }}
        ></div>

        {/* page content */}
        <div className="container content">
          
          {/* header texts */}
          <div className="row text-center mb-3">
            <h1 className="header">Frequently Asked Questions</h1>
            <p className="subText">Your questions answered here</p>
          </div>

          {/* FAQ tabs */}
          <Flex align="center" justify="center" p="5">
            <Accordion allowMultiple width="100%" rounded="xl">

               {/*  Renders faq tabs by inserting the data through the FaqComponent */}
               {/* Also acts as a for loop for each data */}
              {faqs.map((faq, index) => (
                <FaqComponent key={index} {...faq} />
              ))}
            </Accordion>
          </Flex>
        </div>
      </div>

      <Footer />
    </ChakraProvider>
  );
}

export default FAQ;