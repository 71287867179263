import ReactGA from "react-ga4";
import { log, err } from "./log";

// const GA_MEASUREMENT_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID;
const GA_MEASUREMENT_ID='G-ZR4RK04LW9'
log(GA_MEASUREMENT_ID);

const isDevelopment = process.env.NODE_ENV === 'development';
 log(isDevelopment);

export const initGA = () => {
  /**
   * Enable this to troubleshoot in  debug mode.
   * In the browser, inspect element and go to Network and enter "collect" in the filter.
   * If GA is working, you should see a POST request to https://www.google-analytics.com/g/collect...
   */
  // ReactGA.initialize(GA_MEASUREMENT_ID, { debug: true });
  // ReactGA.initialize(GA_MEASUREMENT_ID, { debug: isDevelopment });
  ReactGA.initialize(GA_MEASUREMENT_ID); //Enable for live mode
};

export const logPageView = () => {
  ReactGA.send('pageview');
};